@import '../../../../scss/theme-bootstrap';

// Cart Styling
.cart-block {
  //  padding: 40px 0 0 0;
  width: 310px;
  &__view-link {
    @include h4;
    display: block;
    float: $rdirection;
  }
  &__header {
    @include pie-clearfix;
    border-bottom: solid 1px $color-light-grey;
    @include swap_direction(margin,0.5em 0.5em 0.5em 10px);
    padding-bottom: 0.5em;
    &__item-count {
      float: $rdirection;
    }
  }
  &__items {
    @include swap_direction(padding,10px 0 0 10px);
    color: $color-off-black;
    .product-name {
      line-height: get-line-height(14px, 15px);
    }
  }
  &__products {
    overflow-y: scroll;
    padding: 10px 0;
  }
  &__title {
    @include h4;
    float: $ldirection;
    margin: 0;
    padding: 0;
    color: $color-off-black;
    &__text {
      display: none;
      &--empty {
        display: inline-block;
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
      &--nonempty {
        .cart-block--nonempty & {
          display: inline-block;
        }
      }
      &--added {
        .cart-block--added & {
          display: inline-block;
        }
      }
    }
  }
  &__item-count {
    color: $color-gray;
    //    display: none;
    .cart-block--singular &--singular {
      display: block;
    }
    .cart-block--plural &--plural {
      display: block;
    }
    .cart-block--empty & {
      display: none;
    }
    .cart-block--added & {
      display: none;
    }
  }
  &__items {
    // In the "new" state, only show the items that were just added:
    &--new {
      display: none;
      .cart-block--added & {
        display: block;
      }
    }
    .cart-block--added &--all {
      display: none;
    }
    &__view-bag {
      @include t2;
      display: block;
      padding: 1em 0 2em;
    }
  }
  &__footer {
    padding: 1.25em;
    &__summary {
      @include pie-clearfix;
      display: none;
      margin: 0 0 1em;
      .cart-block--nonempty &,
      .cart-block--added & {
        display: block;
      }
    }
    &__subtotal {
      color: $color-off-black;
      display: block;
      &__label {
        @include h4;
        float: $ldirection;
      }
      &__value {
        @include t2;
        float: $rdirection;
      }
      .cart-block--added & {
        display: none;
      }
    }
    &__button {
      width: 100%;
      &--checkout {
        display: none;
        .cart-block--nonempty &,
        .cart-block--added & {
          display: block;
        }
      }
      &--continue-shopping {
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
    }
  }
}
